// AnyComponent

// Variables
// =========

// Selectors

.root {
    display: flex;
    width: 100%;
    padding: 22px 20px;
    background-color: #fff;
    box-shadow: 0px 5px 25px rgba(15, 16, 17, 0.07);
    border-radius: 4px;
    border: 2px solid transparent;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        border: 2px solid #1373E5;
    }
}

.logo {
    display: block;
    width: 48px !important;
    height: 48px;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
}

.brand {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #0F1011;
    margin-bottom: 5px;
}

.site, .law {
    font-size: 11px;
    line-height: 16px;
    color: #9CA5B0;
}


// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .root {
        width: calc(50% - 5px);
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .root {
        width: calc(33.333% - 7px);
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {
    .root {
        width: calc(25% - 8px);
    }
}
// ============
