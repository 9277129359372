// AnyComponent

// Variables
// =========

// Selectors

.root {
    
}

.title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #0F1011;
    margin-bottom: 10px;
}

.button {
    margin-bottom: 10px;

    & button {
        width: 100%;
    }
}


// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: 10px 0;
    }

    .title {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin-right: 40px;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0px;
    }

    .button {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        margin-bottom: 0;

        & button {
            width: unset;
        }
    }

    .alert {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {

}
// ============
