// LayoutHeader

// Variables
// =========

.header {
  height: 60px;
  line-height: 60px;
  padding: 0;

  width: 100%;
  align-items: stretch;
  align-content: stretch;
}

// =========

// MediaQueries
// ============
