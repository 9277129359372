// AvatarWrapper

// Variables
// =========

// Selectors

.avatarWrapper {
  border-radius: 50%;
  background: #e5eaef;
  box-shadow: 0px 1px 5px rgba(13, 14, 72, 0.09),
    0px 1px 1px rgba(13, 14, 72, 0.07);
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// ============
