// UserSettingsLogsForm

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors
.removeAccaunt__text {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
    margin-bottom: 10px;
}

.removeAccaunt__remove {
    display: flex;
    align-items: center;
    width: max-content;
    color: $red-color;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
    & span {
        margin-left: 10px;
    }
}

// =========

// MediaQueries
// @media (min-width: 768px) {

// 
// ============
