$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-text: #5A6068;
$color-light-grey-border: #CED7E0;
$color-disable-button: #B8D5F7;
$color-hover-button: #398bef;
$color-active-button: #1167d0;
$color-input-text: #73797f;

.law {
  margin-top: 25px;
  font-size: 13px;
  line-height: 20px;
  color: $color-text;
}

.btnGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.btn {
  background: #1373E5!important;
  border-radius: 40px !important;
  padding: 15px 35px !important;
  height: 60px !important;
}

.secure {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #42464B;
}

// MediaQueries
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .law {
    font-size: 10px;
  }
}

@media (max-width: 890px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
