// FeedbackModalFormWrapper

// Variables
// =========

// Selectors

.desctopWrapper {
  max-width: 880px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(13, 14, 72, 0.05),
    0 1px 1px 0 rgba(13, 14, 72, 0.03);

  position: relative;
}

.desctopClose.desctopClose {
  position: absolute;
  right: -36px;
  top: -36px;
  border: none;
}

.mobileWrapper {
  max-width: 880px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 1px 5px 0 rgba(13, 14, 72, 0.05),
    0 1px 1px 0 rgba(13, 14, 72, 0.03);
  margin-left: 15px;
  margin-right: 15px;
}
// =========

// MediaQueries
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
// ============
