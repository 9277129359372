@import '~antd/dist/antd.css';
@import '~pure-react-carousel/dist/react-carousel.es.css';
@import '~react-loading-skeleton/dist/skeleton.css';

html {
  font-size: 16px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

html,
body {
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9FAFC;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Montserrat, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FIXME: rebase to assets
This is workaround for iOS Safari
*/
select,
textarea,
input[type='tel'],
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'] {
  font-size: 16px;
}
