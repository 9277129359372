// DropZone

// Variables
$mainColor: #1373e5;
$errorColor: #e24848;
// =========

// Selectors

.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.paddings {
  padding: 20px;
  box-shadow: 0px 0px 100px 61px rgba(199, 199, 199, 0.84);
}

.imageCentered {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.formHeader {
  font-family: Tahoma, Helvetica, sans-serif !important;
  font-weight: 700;
  line-height: 1.25em;
}

.errorLabel {
  color: $errorColor;
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 1.43em;
  font-weight: 600;
}

.dropZoneCustomizer {
  width: 100%;
  height: 44px;
  background-color: #ffffff !important;
  border: 1px dashed rgba(54, 45, 144, 0.3) !important;
  border-radius: 3px !important;
  margin-bottom: 16px;

  &:hover {
    background-color: #eeeeee !important;
    cursor: pointer;
  }
}

.DropZoneHeader {
  color: $mainColor;
}

.crossRelativeParent {
  position: relative;
}

.btnclose {
  display: flex;
  position: absolute;
  right: -13px;
  top: -16px;
  width: 30px;
  height: 30px;
  background: url(./assets/images/i-close-cross-round.svg) no-repeat 0 0/30px auto;
  cursor: pointer;

  &:hover {
    background-position: 0 -60px;
  }
}

.textForFormats {
  color: rgba(0, 0, 0, 0.35);
}

.choosenFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.labelDirection {
  display: flex;
  height: 100%;
  align-items: center;
}

.version {
  color: #686868;
  font-family: Tahoma, Helvetica, sans-serif !important;
  font-size: 8px;
  line-height: 1.43em;
  font-weight: 400;
}

.btnWrapper {
  display: flex;
  padding: 15px;
  justify-content: center;
}

// =========

// MediaQueries
// ============
