// UserSettingsSettings

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $main-black;
  margin-bottom: 30px;
}

.subHeader {
  display: flex;
  margin-bottom: 20px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0857143px;
  color: $text-color;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.listDesktop {
  margin-bottom: 14px;
  box-shadow: 0px 0px 4px 1px #f1f3f6;

  & > div:nth-child(odd) {
    background: #f9fafc;
    border-bottom: 1px solid #f1f3f6;
  }
  & > div:nth-child(even) {
    background: #ffffff;
    border-bottom: 1px solid #f1f3f6;
  }

  & > div:nth-child(1) {
    background: #f9fafc;
    border-radius: 5px 5px 0px 0px;
  }

  & > div:last-child {
    border-radius: 0px 0px 5px 5px;
    border-bottom: unset;
  }
}

.listDesktopEl {
  display: flex;
  justify-content: space-between;
  padding: 8px 15px 8px 24px;
}

.wrapperBloks {
  & > div:first-child {
    border-top: 1px solid $light-blue-color;
  }
}

// .formWrapper {
//   margin-bottom: 10px;
// }

// =========

// MediaQueries
// ============
