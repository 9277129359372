
.wrapper {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  box-shadow: 0 1px 5px 0 rgba(13, 14, 72, 0.05),
  0 1px 1px 0 rgba(13, 14, 72, 0.03);
  & > div {
    width: 100%;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.header {
  display: flex;
  margin-bottom: 20px;
  &Logo {
    height: 60px;
    margin-right: 10px;
    & img {
      height: 100%;
    }
  }
  &Title {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #0F1011;
  }
}

.headerOffer {
  display: flex;
  margin-bottom: 22px;
}

.logoOffer {
  margin-right: 10px;
}

.logo {
  margin-right: 20px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.textOfferName {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #37404b;
}


.infoOffer {
  display: flex;
  flex-direction: column;
}

.infoOfferHeader {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #37404b;
  margin-bottom: 10px;
}

.infoOfferSubHeader {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0928571px;
  color: #73797f;
  margin-bottom: 10px;
}

.subHeader {
  font-family: Montserrat, sans-serif;
  color: #37404b;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
}

.data {
  display: flex;
  margin-bottom: 10px;
}


.dataLabel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 30px;
}

.dataLabelMain {
  color: #73797f;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 6px;
}

.dataLabelSub {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  letter-spacing: -0.09px;
  line-height: 16px;
}

.ratingLabelSpan {
  height: 30px;
  width: 93px;
  color: #73797f;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 3px;
}

.chart {
  margin-bottom: 5px;
}

.footerBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ratingLabel {
  width: 93px;
  margin-right: 8px;
  display: flex;

  & span {
    text-align: end;
  }
}

.ratingWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-right: 20px;
  margin-top: 12px;
}

.formOffer {
  display: flex;
  margin-bottom: 30px;
}

.footerOffer {
  display: flex;
  flex-direction: column-reverse;
  // justify-content: space-between;
  // flex-wrap: wrap;
}

.leftSideBtns {
  display: flex;
  //margin-bottom: 10px;
}

.rightSideBtns {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.lockImg {
  margin-right: 5px;
}

.footerBtnApply, .footerBtnSend {
  width: 100%;
  margin-bottom: 10px;
}


@media screen and (min-width: 992px) {
  .footerOffer {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .rightSideBtns {
    flex-direction: row;
    width: unset;
  }

  .leftSideBtns {
    max-width: 205px;
    width: 100%;
  }

  .footerBtnApply, .footerBtnSend {
    width: max-content !important;
    margin-bottom: 0;
  }

  .footerBtnApply {
    margin-right: 10px;
  }
}
