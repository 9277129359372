
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #37404b;
  margin-bottom: 10px;
  margin-top: 10px;
}

.field {
  margin-top: 8px;
}
