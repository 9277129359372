// MobilePhoneHeader

// Variables
// =========

.backward {
  display: flex;
  flex: 0 1 20px;
  align-items: center;
  margin-right: 8px;
}

.avatar {
  display: flex;
  cursor: pointer;
}

.logo {
  display: flex;
  width: 130px;
}

.logoHelpChat {
  flex: 1;
}

.avatarHelpChat {
  flex: 0 1 32px;
}

// =========

// MediaQueries
// ============
