// RatingBadge

// Variables
// =========

// Selectors

.wrapper {
  padding: 10px 15px;
}

.title {
  font-size: 11px;
  color: #9CA5B0;
  line-height: 13px;
  margin-bottom: 3px;
}

.badge {
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 2px 7px;
  background: rgba(19, 115, 229, 0.15);
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #1373E5;
}
