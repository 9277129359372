// ChatLawyerFooter

// Variables
// =========

.footerChat {
  visibility: hidden;
  display: flex;
  min-height: 60px;
  width: 100%;
  padding: 22px 6px 20px 20px;
  background-color: #ffffff;
  border-top: 1px solid #ebedf2;
  position: fixed;
  z-index: 100;
  bottom: 0;
}

.textArea {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  min-height: 60px;

  & textarea {
    padding-right: 40px;
    min-height: 40px;
  }
}

.searchInput {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: 1px solid rgba(214, 220, 227, 0.5);
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #1373e5;
  }
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .footerChat {
    height: 60px;
    padding: 10px 15px;
  }

  .searchInput {
    margin-left: 0;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid #1373e5;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .footerChat {
    height: 60px;
    padding: 10px 15px;
  }

  .searchInput {
    margin-left: 0;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid #1373e5;
    }
  }
}

// Medium devices (headerlets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .footerChat {
    height: 60px;
    padding: 10px 15px;
  }

  .searchInput {
    margin-left: 0;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid #1373e5;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .footerChat {
    height: 60px;
    padding: 10px 15px;
  }

  .searchInput {
    margin-left: 0;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid #1373e5;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// ============
