// FormWrapper

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.formWrapper {
  background: #ffffff;
  // box-shadow: 0px 1px 5px rgba(13, 14, 72, 0.05),
  //   0px 1px 1px rgba(13, 14, 72, 0.03);
  // border-radius: 4px;
  padding: 14px 0px 14px 0px;
  //margin-bottom: 10px;
  //border-top: 1px solid $light-blue-color;
  border-bottom: 1px solid $light-blue-color;
}

.header {
  display: flex;
  justify-content: space-between;
}

.headerCollapsed {
  margin-bottom: 11px;
}

.headerText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: $main-black;
}

.collapseBtn {
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline-style: none;
  }
}

.content {
  padding-bottom: 8px;
}

// =========

// MediaQueries
@media (min-width: 1200px) {
  .headerCollapsed {
    margin-bottom: 15px;
  }
}

// ============
