// AnyComponent

// Variables
// =========

// Selectors

.root {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #0F1011;

    & > .cell {
        display: none;
    }

    &.active {
        & > .cell {
            display: flex;
        }
    }
}

.cell {
    display: none;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #E8F1FD;
    padding: 20px 0px 20px 0px;
}

.status {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .cell {
        display: flex;
        border-bottom: 1px solid transparent;
        padding: 20px 0px 30px 0px;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -20px;
        width: calc(100% + 40px);
        height: 1px;
        background-color: #E8F1FD;
    }

    &.active {
        &:after {
            width: calc(100%);
            left: 0px;
        }

        & .arrow {
            transform: rotate(90deg);
        }
    }
}

.arrow {
    margin-right: 3px;
    transform: rotate(-90deg);
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 5px;

    &.date {
        width: max-content;
        font-size: 14px;
        line-height: 22px;
        &.timer {
          background-color: rgba(#E3405E, 0.14);
          color: #E3405E;
          border-radius: 25px;
          padding: 7px 15px 7px 9px;
          font-weight: 600;
        }
    }
}

.button {
    & button {
        width: 100%;
        min-width: 194px;
    }
}

.content {
    font-size: 13px;
    line-height: 20px;
    color: #0F1011;
}

.info {
    cursor: pointer;
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .button {
        & button {
            width: max-content;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .root {
        display: flex;
        align-items: center;
        height: 80px;

        & .cell {
            display: flex;
        }
    }

    .arrow {
        display: none;
    }

    .cell {
        border-bottom: 1px solid transparent;
        padding: 15px 0px 15px 0px;

        &:not(:last-child) {
            margin-right: 3px;
        }

        & > span {
            display: none;
        }
    }

    .button {
        flex: 1 0 204px;

        & button {
            margin-left: auto;
        }
    }

    .status {
        flex: 1 1 130px;
        margin-right: 3px;

        &:after {
            display: none;
        }

        & .cell {
            padding: 15px 0px 15px 0px;
            margin-right: 0;
        }
    }

    .sum {
        flex: 1 1 63px;
    }

    .percent {
        flex: 1 1 90px;
    }

    .returnSum {
        flex: 1 1 90px;
    }

    .applyDate {
        flex: 1 0 95px;
    }

    .returnDate {
        flex: 1 0 159px;
    }

    .way {
        flex: 1 0 130px;

        & .info {
            cursor: pointer;
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1500px) {
    .status {
        flex: 1 1 150px;
    }

    .sum {
        flex: 1 1 130px;
    }

    .percent {
        flex: 1 1 130px;
    }

    .returnSum {
        flex: 1 1 130px;
    }

    .applyDate {
        flex: 1 0 130px;
    }

    .returnDate {
        flex: 1 0 180px;
    }
}
// ============
