// PaymentCardBinding

// Variables
// =========

// Selectors
.header {
  margin-bottom: 30px;

  & h2 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #37404b;
  }
}

.information {
  & p {
    margin-bottom: 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #73797f;
  }
}

.nonAlign.nonAlign {
  & p {
    text-align: unset;
  }
}

.input {
  margin-bottom: 20px;
  width: 100%;
}

.errorMessage {
  margin-top: 6px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #e26b84;
}

.unknownMessage {
  margin-top: 6px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #eb934b;
}

.button {
  margin-bottom: 20px;
  width: 100%;

  & img {
    margin-left: 10px;
  }
}

.iframe {
  height: fit-content;
}

// =========

// MediaQueries
// ============
