// UserSettings

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.drawerSidebar {
  width: 255px;
  flex-shrink: 0;
  background-color: #ffffff;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drawerSidebar__inner {
  & > div {
    display: flex;
    align-items: center;
    padding: 17px 20px 17px 20px;
    color: $main-black;
    border-top: 1px solid $light-blue-color;
    border-bottom: 1px solid $light-blue-color;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
      background-color: $light-blue-color;
      color: $main-color;
    }
    & > img {
      margin-right: 15px;
    }
  }
}

.tabActive {
  background-color: $light-blue-color;
  & span {
    color: $main-color;
  }
}

// .sidebarHeader {
//   color: #37404b;
//   font-family: Montserrat, sans-serif;
//   font-size: 22px;
//   letter-spacing: 0;
//   line-height: 28px;
//   margin-bottom: 20px;
// }

.sidebarLogout {
  display: flex !important;
  align-items: center;
  height: unset;
  margin-left: 22px;
  padding: 0 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: $text-color !important;
  & img {
    margin-right: 12px;
  }
}

// .sidebarLogout, .sidebarRemove {

//   padding: 0px !important;

//   & img {
//     margin-right: 3px;
//   }

//   & span {
//     color: $text-color;
//     font-family: Montserrat, sans-serif;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 20px;
//   }

//   &:hover {
//     cursor: pointer;
//   }

//   border: 1px solid transparent;
//   &:focus {
//     border: 1px solid #1373e5;
//     border-radius: 2px;
//     outline-style: none;
//   }
// }

.content {
  padding: 20px 16px 21px;
}

.drawerContent {
  width: 100%;
  // background-color: rgba(214, 220, 227, 0.15);
  background-color: #fff;
  // box-shadow: -1px 0 0 0 #eeeeee;
  border-left: 1px solid $light-blue-color;
}

.closeButton {
  letter-spacing: 0;
  background: transparent !important;
  outline: 0;
  line-height: 1.5715;
  // display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  // padding: 4px 15px;
  // font-size: 14px;
  // color: rgba(0, 0, 0, 0.65);

  // & span {
  //   margin-bottom: 0;
  //   margin-left: -5px;
  //   margin-top: 2px;
  // }

  &:hover,
  &:active,
  &:focus {
    border-color: #ffffff;
  }
}

.mobileDrawer {
  width: 100%;
}

.mobileDrawerHeader {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  height: 44px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.07), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.mobileHeaderBackward {
  margin-right: 20px;
}

.mobileHeaderClose {
  border: none !important;
  padding: 0 !important;
}

.mobileHeaderLabel {
  display: flex;
  flex: 1 0 116px;
  color: $main-black;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  & > span {
    font-size: 16px;
    line-height: 22px;
  }
}

// .sidebarLogoutMobile, .sidebarRemoveMobile {

// }

// .wrapTabs {
//   & > div:last-child {
//     border-bottom: 1px solid $light-blue-color;
//   }
// }

// .tab {
//   display: flex;
//   align-items: center;
//   width: fit-content;
//   height: 54px;
//   //padding: 11px 20px;
//   //background: rgba(214, 220, 227, 0.248689);
//   //border-radius: 4px;
//   //margin-bottom: 4px;
//   border-top: 1px solid $light-blue-color;

//   & span {
//     font-family: Montserrat, sans-serif;
//     font-weight: 600;
//     font-size: 13px;
//     line-height: 20px;
//     margin-left: 15px;
//   }

//   &:hover {
//     cursor: pointer;
//   }

//   //border: 1px solid transparent;
//   &:focus {
//     border: 1px solid #1373e5;
//     outline-style: none;
//   }

//   & .arrowRight {
//     margin-left: auto;
//   }
// }

// .tabActive {
//   display: flex;
//   width: fit-content;
//   //padding: 11px 20px;
//   background: #ffffff;
//   box-shadow: 0px 3px 10px rgba(13, 14, 72, 0.0720334),
//     0px 1px 1px rgba(13, 14, 72, 0.0528846);
//   border-radius: 4px;
//   margin-bottom: 4px;

//   & span {
//     font-family: Montserrat, sans-serif;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 13px;
//     line-height: 17px;
//     text-align: center;
//     color: #1373e5;
//   }
// }

// .mobileTab {
//   //justify-content: center;
//   margin-bottom: unset;
//   width: unset;
// }

.lawText {
  margin-bottom: 20px;

  & > p {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
  }
}



// =========

// MediaQueries

@media only screen and (min-width: 768px) {
  .content {
    padding: 30px 20px;
  }
}

// ============
