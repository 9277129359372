// UserSettingsChangePhoneForm

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.fieldWrapper {
  margin-top: 8px;
}

.errorMessage {
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: $red-color;
}

// input {
//   color: $main-black !important;
//   &::placeholder {
//     color: $main-black !important;
//   }
// }

// =========

// MediaQueries

// ============
