// Register
// =======

// Variables
// =========

$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-disable-button: #B8D5F7;
$color-hover-button: #398bef;
$color-item: #42464B;
$color-light-grey-border: #CED7E0;
$color-placeholer: #87919C;
$color-text: #5A6068;

// InputUniversal
// основные стили, фирст мобаил
// для десктопа в медиа запросах

.wrapper {
  margin-top: 15px;
  position: relative;
}

.input {
  height: 60px !important;
  width: 100% !important;
  border: 1px solid $color-light-grey-border;
  border-radius: 5px !important;
  padding: 23px !important;
  padding-bottom: 8px !important;
  font-size: 15px !important;
  line-height: 28px !important;
  transition: all 0.3s ease !important;

  &:focus {
    outline: none !important;
    border: 1px solid $color-main-blue !important;
  }

  &:hover {
    border: 1px solid $color-main-blue !important;
  }

  &::placeholder {
    color: $color-placeholer !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 28px !important;
  }
}

.select {
  height: 60px !important;
  width: 100% !important;
  border: 1px solid $color-light-grey-border !important;
  border-radius: 5px !important;
}

.label {
  position: absolute;
  top: 6px;
  left: 24px;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  color: $color-main-blue;
}

.error {
  color: #E3405E;
  font-size: 12px;
  line-height: 22px;
}

.inputError {
  border-color: #E3405E !important;
}

// =========

// MediaQueries
@media (max-width: 575.98px) {
  .wrapper {
    margin-bottom: 10px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .wrapper {
    margin-bottom: 10px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1141px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// ============

