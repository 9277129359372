// AnyComponent

// Variables
// =========

// Selectors

.root {
  position: relative;
  margin-right: -16px;
  margin-bottom: 5px;
  margin-left: -5px;
}

.slide {
  position: relative;
  min-height: 200px;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: -20px;
  border-radius: 5px;
  box-shadow: 0 5px 25px rgba(15, 16, 17, 0.07);
}

.wrap {
  position: relative;
  z-index: 10;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.logo {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 3px;
}

.brand {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #0F1011;
}

.info {
  font-size: 11px;
  line-height: 16px;
  color: #0F1011;
}

.nextArrow, .prevArrow {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 30px;
  background: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}

.prevArrow {
  left: -40px;
}

.nextArrow {
  right: -40px;
  transform: rotate(180deg);
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .root {
    margin-right: 0px;
    margin: 0 40px 15px;

  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {

}

// ============
