// Button
// =======

// Variables
// =========

// Selectors
// основные стили, фирст мобаил
// для десктопа в медиа запросах

.wrapReverse {
  flex-direction: row-reverse;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 40px;
  font-weight: 600;

  transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
}

.big {
  font-size: 15px;
  line-height: 28px;
  padding: 16px 14px;
}

.small {
  font-size: 14px;
  line-height: 18px;
  padding: 14px 10px;
}

.primary {
  border: 1px solid #1373E5;
  background-color: #1373E5;
  color: #FFFFFF;
  box-shadow: 0 5px 23px rgba(19, 115, 229, 0.3);

  & .svg {
    & svg {
      fill: #FFFFFF;
    }
  }

  &:hover {
    background-color: #388CF0;
    border: 1px solid #388CF0;
    box-shadow: none;
  }
  &:active {
    box-shadow: 0 5px 23px rgba(19, 115, 229, 0.3);
  }
}

.secondary {
  border: 1px solid #1373E5;
  color: #1373E5;

  &:hover {
    color: #FFFFFF;
    background-color: #1373E5;
    & .svg {
      & svg {
        fill: #FFFFFF;
      }
    }
  }
  &:active {
    background-color: transparent;
    color: #1373E5;

    & .svg {
      & svg {
        fill: #1373E5;
      }
    }
  }

  & .svg {
    & svg {
      fill: #1373E5;
    }
  }
}

.disabled {
  cursor: default;
  box-shadow: none;

  &.primary {
    background-color: #B8D5F7;
    &:hover {
      background-color: #B8D5F7;
    }
    &:active {
      box-shadow: none;
    }
  }
  &.secondary {
    border: 1px solid #B8D5F7;
    color: #B8D5F7;
    &:hover {
      background-color: transparent;
    }
    & .svg {
      & svg {
        fill: #B8D5F7;
      }
    }
  }
}

.suffix {
  display: flex;
  justify-content: center;
  align-items: center;

  &R {
    margin-left: 10px;
  }
  &L {
    margin-right: 10px;
  }
}

.svg {
  & svg {
    transition: fill 0.3s;
  }
}


// =========

// MediaQueries
@media (max-width: 575.98px) {
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
// ============
