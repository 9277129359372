// UserSettingsSubscription

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

// .header {
//   display: flex;
//   align-items: center;
//   margin-bottom: 14px;
// }

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $main-black;
  margin-bottom: 30px;
}

.subtitle {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: $text-color;
  margin-bottom: 20px;
}

.headerText {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $main-black;
  margin-right: 11px;
}

.subHeader {
  display: flex;
  margin-bottom: 14px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.0857143px;
  color: #73797f;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.list {
  margin-bottom: 20px;
}

.infoPropsList {
  display: flex;
  flex-direction: column;
}

.listCheckbox_notify {
  & .itemCheckbox {
    &:last-child {
      border-bottom: 1px solid $light-blue-color;
    }
  }
}

.itemCheckbox {
  width: 100%;
  border-bottom: 1px solid $light-blue-color;
  padding-bottom: 10px;
  padding-top: 10px;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
}

.listEl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.listElPropName {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;
}

.listElPropValue {
  display: flex;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;

  & > :first-child {
    margin-right: 6px;
  }
}

.infoAvatar {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 0px;
}

.infoAvatarMobile {
  padding: 0px;
}

.avatar {
  width: 152px;
  height: 152px;
  background: #e5eaef;
  border-radius: 50%;
}

.lawText {
  margin-bottom: 20px;

  & > p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.0928571px;
    color: #73797f;
  }
}

// =========

// MediaQueries
// ============
