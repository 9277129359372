// UserSettings

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $main-black;
  &__titleInner {
    font-size: 20px;
    line-height: 28px;
    margin-right: auto;
  }
  & .headerIcon {
    display: flex;
    align-items: center;
    position: relative;
    right: unset;
    margin-right: 20px;
  }
}

.headerIcon {
  position: absolute;
  right: 0;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline-style: none;
  }
}

.info {
  position: relative;
  margin-bottom: 10px;
}

.infoPropsList {
  text-align: center;
}

.infoId {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: $light-grey-text;
  margin-bottom: 10px;
}

.infoFio {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: $main-black;
  margin-bottom: 10px;
  & > div:not(:last-child) {
    margin-right: 4px;
  }
}

.infoPhone {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: $main-black;
  margin-bottom: 5px;
}

.infoEmail {
  font-size: 13px;
  line-height: 20px;
  color: $text-color;
}

// .listEl {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 10px;
// }

// .listElPropName {
//   font-family: Montserrat, sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 13px;
//   line-height: 17px;
//   color: #37404b;
// }

// .listElPropValue {
//   display: flex;
//   font-family: Montserrat, sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 13px;
//   line-height: 17px;
//   color: #37404b;

//   & > :first-child {
//     margin-right: 6px;
//   }
// }

.infoAvatar {
  display: flex;
  justify-content: center;
  background: #ffffff;
  margin-bottom: 10px;
}

.infoAvatarMobile {
  padding: 0px;
}

.avatar {
  width: 71px;
  height: 71px;
  background: #e5eaef;
  border-radius: 50%;
}

.lawText {
  margin-bottom: 20px;

  & > p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.0928571px;
    color: #73797f;
  }
}

.subheader {
  margin-bottom: 20px;

  & > p {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
  }
}

.formWrapper {
  margin-bottom: 10px;
}

.changeAvatar {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  position: relative;
  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &__add {
    position: absolute;
    bottom: -6px;
    right: -6px;
    border-radius: 50%;
    background-color: #fff;
  }
  &__input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }
}

.indicators {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px 12px 0;
  margin-bottom: 20px;

  &__title {
    font-size: 11px;
    line-height: 16px;
    color: $text-color;
    margin-bottom: 2px;
  }

  &__value {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: $main-black;
  }

  &__label {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    &Color {
      background-color: $light-blue-color;
    }
    & img {
      position: absolute;
      //left: calc(50% - 20px);
    }
  }
}

.sidebarLogout {
  display: inline-flex;
  align-items: center;
  height: unset;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sidebarLogout, .sidebarRemove {

  padding: 0px !important;

  & img {
    margin-right: 3px;
  }

  & span {
    color: $text-color;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  border: 1px solid transparent;
  &:focus {
    border: 1px solid #1373e5;
    border-radius: 2px;
    outline-style: none;
  }
}

.wrapTabs {
  & > div:last-child {
    border-bottom: 1px solid $light-blue-color;
  }
}

.tab {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 54px;
  //padding: 11px 20px;
  //background: rgba(214, 220, 227, 0.248689);
  //border-radius: 4px;
  //margin-bottom: 4px;
  border-top: 1px solid $light-blue-color;

  & span {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    margin-left: 15px;
  }

  &:hover {
    cursor: pointer;
  }

  //border: 1px solid transparent;
  &:focus {
    border: 1px solid #1373e5;
    outline-style: none;
  }

  & .arrowRight {
    margin-left: auto;
  }
}

.tabActive {
  display: flex;
  width: fit-content;
  //padding: 11px 20px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(13, 14, 72, 0.0720334),
    0px 1px 1px rgba(13, 14, 72, 0.0528846);
  border-radius: 4px;
  margin-bottom: 4px;

  & span {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #1373e5;
  }
}

.mobileTab {
  //justify-content: center;
  margin-bottom: unset;
  width: unset;
}

.editProfile_btn {
  margin-top: 10px;
  width: 100%;
}


// =========

// MediaQueries

@media only screen and (min-width: 768px) {
  .indicators {
    padding: 10px 70px 0;
    &__title {
      font-size: 13px;
      line-height: 20px;
    }
    &__value {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .editProfile_btn {
    width: unset;
  }
  .avatar {
    width: 80px;
    height: 80px;
  }
  .infoFio {
    font-size: 20px;
    line-height: 28px;
  }
  .infoAvatar {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .indicators {
    padding: 10px 0 0;
    margin-bottom: 30px;
    &__label {
      width: 70px;
      height: 70px;
    }
  }
  .infoId {
    margin-bottom: 15px;
  }
}

// ============
