// UserSettingsActiveSubscribe

.root {
  margin-bottom: 30px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 10px;
  color: #0F1011;
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  background: #FFFFFF;
  box-shadow: 0 5px 25px rgba(15, 16, 17, 0.07);
  border-radius: 5px;
  padding: 16px;
}

.name {
  font-weight: 600;
  color: #0F1011;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    right: -8px;
    top: -16px;
    width: 1px;
    height: calc(100% + 32px);
    background-color: #E8F1FD;
  }
}

.value {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  color: #0F1011;
}

.valid {
  font-weight: 400;
  font-size: 11px;
  line-height: 1.5;
  color: #9CA5B0;
}

.card {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  color: #0F1011;
}

.action {
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    cursor: pointer;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    gap: 16px 32px;
  }

  .name {
    &:after {
      right: -16px;
    }
  }
}
