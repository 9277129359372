// Select

// Variables
// =========

// Selectors
.error {
  border: 1px solid #e24848 !important;
}

.good {
  border: 1px solid #1373e5 !important;
}

.label {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
}

.longLabel {
  width: max-content;
}
// =========

// MediaQueries
// ============
