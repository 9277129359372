// RootLayout

// Variables
// =========

// Selectors
.contentBlurred {
  filter: blur(12px) brightness(0.47) drop-shadow(16px 16px 20px rgba(0, 0, 0, 0.47));
}

.content {
  margin-top: 48px;
  margin-bottom: 64px;
  padding-bottom: 10px;
  background-color: #F9FAFC;
}

// =========

// MediaQueries
@media (min-width: 1200px) {
  .content {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// ============
