
.ant-drawer {
  min-height: 100%;
  height: auto;

  & .ant-drawer-mask {
    backdrop-filter: blur(5px);
    background-color: rgba(55, 64, 75, 0.85);
  }

  & .ant-drawer-header-title {

    & button {
      position: fixed;
      z-index: 200;
      top: 45px;
      right: calc(770px);
      width: 38px;
      height: 38px;
      border-radius: 19px;

      & .anticon {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        letter-spacing: 0;
        background: transparent !important;
        outline: 0;
        line-height: 1.5715;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        background-image: none;
        border: 1px solid #ffffff;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-user-select: none;
        user-select: none;
        touch-action: manipulation;

        & svg {
          fill: white;
        }
      }
    }
  }

  & .ant-drawer-content-wrapper {
    overflow-y: auto !important;
    width: 740px !important;
  }

  & .ant-drawer-header {
    display: none;
  }

  & .ant-drawer-close {
    // z-index: -1 !important;
  }

  & .ant-drawer-body {
    display: flex;
    padding: 30px;
    overflow: unset !important;
  }

  & .ant-drawer-content {
    overflow: unset;
  }

  & .ant-drawer-wrapper-body {
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .ant-drawer {
    & .ant-drawer-header {
      display: flex;
      height: 0;
      padding: 0;
    }
  }
}
