// AnyComponent

// Variables
// =========

// Selectors

.root {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.dashboard {
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(15, 16, 17, 0.07);
    border-radius: 5px;
    padding: 20px;
}

.count {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #1373E5;
    gap: 10px;

    &.gray {
        color: #CED7E0;
    }
}

.subtitle {
    font-size: 13px;
    line-height: 20px;
    color: #9CA5B0;
}


// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .root {
        flex-direction: row;
        gap: 20px;
    }

    .subtitle {
        padding-right: 50px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .dashboard {
        max-width: 249px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {

}
// ============
