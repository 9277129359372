
.offerModal {
  scroll-behavior: smooth;
  display: none;
  background-color: #5A6068;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;

  &Open {
    display: block;
  }
}

.footerButton {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  & > * {
    width: 50%;
  }
}

.button {
  background: #1373E5 !important;
  box-shadow: 0 5px 23px rgba(19, 115, 229, 0.3) !important;
  border-radius: 40px !important;
  min-height: 60px !important;
  min-width: 250px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 28px !important;
  color: #FFFFFF !important;
}

.protected {
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    margin-right: 10px;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #0F1011;
  margin-bottom: 10px;
  & img {
    margin-right: 25px;
    cursor: pointer;
  }
}

.description {
  font-size: 15px;
  line-height: 22px;
  color: #5A6068;
  margin-bottom: 20px;
}

.category {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  color: #0F1011;
}

// ============


// MediaQueries
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .footerButton {
    flex-direction: column;
    & > * {
      width: 100%;
    }
    .protected {
      margin: 0;
      margin-top: 20px;
    }
  }
}

@media (max-width: 890px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .description {
    margin-bottom: 30px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .description {
    margin-bottom: 30px;
  }
}
