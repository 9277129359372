// UserSettingsChangeEmailForm

// Variables
// =========

// Selectors

.header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.headerText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #37404b;
  margin-right: 11px;
}

.subHeader {
  display: flex;
  margin-bottom: 14px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.0857143px;
  color: #73797f;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.list {
  margin-bottom: 20px;
}

.infoPropsList {
  display: flex;
  flex-direction: column;
}

.listEl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.listElPropName {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;
}

.listElPropValue {
  display: flex;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;

  & > :first-child {
    margin-right: 6px;
  }
}

.infoAvatar {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 0px;
}

.infoAvatarMobile {
  padding: 0px;
}

.avatar {
  width: 152px;
  height: 152px;
  background: #e5eaef;
  border-radius: 50%;
}

.lawText {
  margin-bottom: 20px;

  & > p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.0928571px;
    color: #73797f;
  }
}

.fieldWrapper {
  margin-top: 0;
}

.formFooter {
  padding-top: 11px;
}

.errorMessage {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #e26b84;
}

// =========

// MediaQueries

// Universal Mobile
@media (max-width: 1199.98px) {
  .fieldWrapper {
    margin-top: 8px;
  }
}

// ============
