// FIXME: По мере необходимости раскидывать по модулям

@import './assets/styles/old_overrides';

@import './assets/styles/common';


#launcher {
  display: none !important
}


