// MultiLogin

// Variables
// =========

// Selectors

.root {
  padding: 10px 16px;
  max-width: 1330px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 10px;

  & > .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #0F1011;
  }

  & > .how {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1373E5;
    gap: 5px;
    cursor: pointer;
  }

  & > .work {
    display: none;
  }

  & > .subtitle {
    font-size: 13px;
    line-height: 20px;
    color: #42464B;
    margin-bottom: 10px;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #0F1011;
  // margin-bottom: 10px;
}

.subtitle {
  font-size: 13px;
  line-height: 20px;
  color: #42464B;
  margin-bottom: 10px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.search {
  margin-bottom: 10px;
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .title {
    margin-right: 40px;
    font-size: 28px;
    line-height: 42px;
  }
  .subtitle {
    margin-bottom: 20px;
  }
  .work {
    display: unset;
  }
  .wrapper {
    justify-content: flex-start;

      & > .title {
        font-size: 28px;
        line-height: 42px;
      }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .root {
    padding: 20px;
  }
}
// ============
