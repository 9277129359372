// Variables
// =========

// Selectors

.root {
  background: #FFFFFF;
  color: #0F1011;
  padding: 38px 4px 8px;
}

.close {
  position: absolute;
  top: -24px;
  right: 24px;
  cursor: pointer;
  fill: #1373E5;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  fill: #1373E5;
}

.item {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #0F1011;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #E8F1FD;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}


// =========

// MediaQueries

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}
// ============
