.root {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  color: #0F1011;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 10px;
}

.arrow {
  min-width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(19, 115, 229, 0.1);

    & > svg {
      fill: #1373E5;
    }
  }
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #42464B;
  margin-bottom: 20px;
}
