// CheckBox

// Variables
// =========

// Selectors
.labelWrap {
  & > label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    & span:nth-child(2) {
      margin-right: auto;
      padding: 0;
    }
  }
}

.labelText {
  color: #0F1011;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: -0.09px;
  line-height: 20px;
  margin-right: auto;
}
// =========

// MediaQueries
// ============
