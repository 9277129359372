// UserSettingsActivity

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $main-black;
  margin-bottom: 30px;
}

// .headerText {
//   font-family: Montserrat, sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 13px;
//   line-height: 20px;
//   color: $text-color;
//   margin-right: 11px;
// }

.subHeader {
  display: flex;
  margin-bottom: 20px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: $text-color;
  letter-spacing: -0.0857143px;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.listDesktop {
  margin-bottom: 14px;

  & .listDesktopEl:nth-child(odd) {
    background: $background-color;
  }
  & .listDesktopEl:nth-child(even) {
    background: #ffffff;
  }

  & > .listDesktopEl:nth-child(1) {
    background: $background-color;
  }

  // & .listDesktopEl:last-child {
  //   border-radius: 0px 0px 5px 5px;
  //   border-bottom: 1px solid #f1f3f6;
  // }
}

.listDesktopEl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  & > div {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.0928571px;
    color: $main-black;
  }

  .flexWidth1 {
    display: flex;
    align-items: center;
    flex: 1 0 150px;
  }
  
  .flexWidth2 {
    flex: 1 0 75px;
    color: $text-color;
  }
  
  .flexWidth3 {
    flex: 0 0 40px;
    text-align: center;
    font-weight: 600;
  }
}

.listDesktopElHeader.listDesktopElHeader {
  & > div {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.0928571px;
    color: #37404b;
  }
}



// =========

// MediaQueries

// @media (min-width: 1200px) {
//   .header {
//     display: flex;
//   }
// }


// @media (max-width: 374.98px) {
//   .listDesktopEl {
//     display: flex;
//     justify-content: space-between;
//     padding: 8px 10px 8px 16px;

//     & > div {
//       font-family: Montserrat, sans-serif;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 11px;
//       line-height: 20px;
//       letter-spacing: -0.0928571px;
//       color: #73797f;
//     }
//   }

//   .listDesktopElHeader.listDesktopElHeader {
//     & > div {
//       font-family: Montserrat, sans-serif;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 11px;
//       line-height: 20px;
//       letter-spacing: -0.0928571px;
//       color: #37404b;
//     }
//   }

//   .flexWidth1 {
//     flex: 1 0 170px;
//   }

//   .flexWidth2 {
//     flex: 0 0 75px;
//   }

//   .flexWidth3 {
//     flex: 0 0 40px;
//   }
// }
// ============
