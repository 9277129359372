// RatingBadge

// Variables
// =========

// Selectors

.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 2px 6px 2px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  margin: auto;
}

.success {
  color: #10B97B;
  background-color: rgba(16, 185, 123, 0.15);
}

.warning {
  color: #FABD48;
  background-color: rgba(250, 189, 72, 0.15);
}

.danger {
  color: #E3405E;
  background-color: rgba(227, 64, 94, 0.15);
}

.iconPadding {
  padding-right: 4px;
  padding-top: 1px;
}
// =========

// MediaQueries
// ============
