// Input

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors
.labelText {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.09px;
  line-height: 20px;
}

.error {
  border: 1px solid $red-color !important;
  &::placeholder {
    color: $light-grey-text !important;
  }
}

.good {
  border: 1px solid $main-color !important;
  &::placeholder {
    color: $main-black !important;
  }
}

.inputWrapper {
  position: relative;
}

.suffix {
  position: absolute;
  right: 12px;
  top: 9px;
}

.inputUsual {
  padding-right: 24px;
  color: $main-black !important;
  &::placeholder {
    color: $light-grey-text !important;
  }
}

.inputLabel {
  color: $main-black;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 4px;
}

.longLabel {
  width: max-content;
}
// =========

// MediaQueries
// ============
