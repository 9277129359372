// AnyComponent

// Variables
// =========

// Selectors

.root {
  width: 100%;
  line-height: 1;
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #0F1011;
  margin-bottom: 17px;
}

.subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #0F1011;
  margin-bottom: 20px;
}

.company {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.logo {
  display: block;
  width: 48px !important;
  height: 48px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;
}

.brand {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #0F1011;
  margin-bottom: 5px;
}

.site, .law {
  font-size: 11px;
  line-height: 16px;
  color: #9CA5B0;
}

.form {
  margin-bottom: 20px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.button {
  & > button {
    width: 100%;
  }
}

.attention {
  font-size: 13px;
  line-height: 20px;
  color: #42464B;
}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .form {
    & > .fields {
      flex-direction: row !important;
      gap: 30px;
    }
  }

  .login, .password {
    width: calc(50% - 15px);
  }

  .button {

    & > button {
      width: calc(50% - 15px);
    }
  }

  .title {
    font-size: 24px;
    line-height: 28px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {

}

// ============
