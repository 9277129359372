// UserSettingsLogsForm

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors

.header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.headerText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #37404b;
  margin-right: 11px;
}

.subHeader {
  display: flex;
  margin-bottom: 14px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.0857143px;
  color: #73797f;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.listDesktop {
  margin-bottom: 14px;
  // box-shadow: 0px 0px 4px 1px #f1f3f6;

  & .listDesktopEl:nth-child(odd) {
    background: $background-color;
    // border-bottom: 1px solid #f1f3f6;
  }
  & .listDesktopEl:nth-child(even) {
    background: #ffffff;
    // border-bottom: 1px solid #f1f3f6;
  }

  & > .listDesktopEl:nth-child(1) {
    background: $background-color;
    // border-radius: 5px 5px 0px 0px;
  }

  & .listDesktopEl:last-child {
    // border-radius: 0px 0px 5px 5px;
    border-bottom: 1px solid $light-blue-color;
  }
}

.listDesktopEl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 10px;

  & > div {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.0928571px;
    color: $main-black;
  }
}

.listDesktopElHeader.listDesktopElHeader {
  padding: 10px;
  & > div {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: $main-black;
  }
}

.flex1 {
  flex: 1 0 122px;
}
.flex2 {
  flex: 1 0 90px;
}
.flex3 {
  flex: 0 0 65px;
}

// =========

// MediaQueries
@media (min-width: 380px) {
  .flex3 {
    flex: 0 0 105px;
  }
}
// ============
