@import './variables';

.checkbox {
  margin-bottom: 16px;
  .span {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.form {
  padding-bottom: 24px;
}
.ant-input,
.ant-input-affix-wrapper {
  color: #73797f;
  font-family: 'Montserrat';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 17px;
  height: 40px;
  border: 1px solid rgba(214, 220, 227, 0.5);
  border-radius: 4px;
  background-color: #ffffff;
}
.button {
  margin-top: 16px;
}
.close-button {
  position: absolute;
  top: -35px;
  right: -35px;
  z-index: 100000;
  &:hover,
  &:focus {
    border-width: 0 !important;
  }
  img {
    height: 24px;
    width: 24px;
    margin-right: 0 !important;
  }
}
.ant-input-affix-wrapper {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  .ant-input-suffix {
    // padding: 7.5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input {
    height: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.ant-input-suffix {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  .country-flag {
    height: 25px !important;
    width: 32px !important;
    border: 0.5px solid #ebebeb;
    border-radius: 2px;
  }
}
.form {
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
  .input {
    margin-bottom: 16px;
    .error {
      border-color: $error-color;
    }
    span {
      color: $error-color;
      font-size: 13px;
    }
  }
}

.btn-sectipn {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 21px;
}

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.card-form {
  width: 100%;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
}

.count-down {
  & > div {
    display: inline;
  }
}

.ant-btn.count-down-btn {
  color: #37404b;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: right;
  height: auto;
  img {
    height: 14px;
    width: 14px;
  }
}

.iframe-modal {
  position: absolute;
}

.iframe-3dSecure {
  width: 520px;
}

.btn-gosuslugi {
  &.ant-btn {
    height: auto;
    width: 100%;
    padding: 14px 20px 14px 20px;
    border: 1px solid #dcdedf;
    border-radius: 4px;
    background-color: #ffffff;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #37404b;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 17px;
    }
    img.logo {
      margin-left: 0;
      height: 16px;
      width: auto;
    }
  }
}

.card-section {
  border: 1px solid #e5eaef;
  border-radius: 4px;
  &:active,
  &:hover,
  &:focus {
    border: 1px solid #1373e5;
  }
  & > div {
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  // .card_number-filled {
  //   input {
  //     left: -96px;
  //   }
  // }
  .ant-input-affix-wrapper {
    border-color: transparent;
    padding-left: 0;
    border-width: 0;
    &:focus,
    &:hover,
    &:active,
    &:visited {
      border-color: transparent;
      box-shadow: none;
    }
    &.ant-input-affix-wrapper-focused {
      border-width: 0;
      box-shadow: none;
    }
    .ant-input {
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .ant-input-prefix {
      margin-right: 0;
      line-height: 20px;
      padding-top: 10px;
      background-color: #fff;
      z-index: 10;
    }
  }
  .ant-input {
    border-width: 0;
    border-color: transparent;
    box-shadow: none;
    height: 28px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 0;
    &:focus,
    &:hover,
    &:active {
      border-color: transparent;
    }
    &[name='expiry_date'],
    &[name='cvv'] {
      border-left: 1px solid #e5eaef;
    }
  }
}
