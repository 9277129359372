// Header
// =======

// Variables

$color-border-menu: #EAF1F9;
$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-item: #42464B;

// =========

// Selectors
// основные стили, фирст мобаил
// для десктопа в медиа запросах

.header {
  background-color: #fff;
  border-bottom: 1px solid $color-border-menu;

  &Inner {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Logo {
    cursor: pointer;
    & img {
      width: 80%;
    }
  }
}

.wrapper {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.close {
  cursor: pointer;
  margin-right: 10px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  &Option, &SignIn {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    & img {
      margin-right: 10px;
    }
  }

  &Option {
    width: auto;
  }

  &SignIn {
    color: $color-main-blue;
  }

  &OptionImgArrow{
    margin-right: 0;
    margin-left: 6px;
  }

  &Search {
    cursor: pointer;
  }
}

.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 5px 21px rgba(15, 16, 17, 0.21);
  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    font-size: 10px;
    line-height: 24px;
    color: $color-item;
    text-align: center;
    cursor: pointer;
    & img {
      height: 20px;
    }
    & span {
      height: 15px;
    }
  }
}

// =========

// MediaQueries
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .headerLogo img {
    width: 100%;
  }

  .headerInner {
    height: 70px;
  }

  .nav {
    width: 216px;
    position: relative;
    box-shadow: none;
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

@media (min-width: 1141px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .headerInner {
    height: 64px;
  }

  //.menu {
    //display: flex;
  //}

  .nav {
    display: none;
  }

}
// ============

