// UserSettingsLogsForm

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors
.listLanguage {
    margin-bottom: -10px;
    margin-top: -10px;
}

.listLanguage__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 13px 10px 11px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
        background-color: $hover-blue;
    }
    &.listLanguage__select {
        background-color: $hover-blue;
        & .listLanguage__item_check {
            display: block;
        }
    }
}

.listLanguage__item_name {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $main-black;
    margin-right: auto;
    margin-left: 10px;
}

.listLanguage__item_check {
    display: none;
}

// =========

// MediaQueries
// @media (min-width: 768px) {

// 
// ============
