@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500&display=swap&subset=cyrillic-ext');
@import './variables.scss';
@import './button.scss';
@import './recaptcha.scss';
@import "slick";
@import "./antd/main";

body {
  font-family: Montserrat, sans-serif !important;
  scroll-behavior: smooth;
}

a {
  color: $main-color;
  &:hover {
    color: $main-color;
  }
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #f1f3f6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1f3f6;
}

/* hiding arrows (Chrome, Safari, Edge, Opera) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* hiding arrows for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 20px;
  font-weight: 300;
}

h5 {
  font-size: 18px;
  font-weight: 600;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
}

.inactive:hover,
.inactive.ant-menu-item-selected,
.inactive.ant-menu-item-active {
  border-bottom: 2px solid transparent !important;
}

.ant-input {
  &:hover,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    border-color: $main-color;
  }

  &.error {
    border-color: $error-color;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $main-color;
  border-color: $main-color;
}

.site-card-wrapper {
  padding: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  background-color: #f9fafc;
  height: 100%;

  h2 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  & > h1 {
    margin-bottom: 11px;
  }

  & > p {
    font-family: Montserrat;
    margin-bottom: 20px;
    color: #73797f;
    font-size: 13px;
    letter-spacing: -0.09px;
    line-height: 20px;
  }

  .ant-card-head {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-card-head-title {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 28px;
  }

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer {
    border-top: 1px solid rgba(214, 220, 227, 0.4);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;

    .item {
      font-size: 11px;
      padding-right: 20px;
      color: #73797f;

      span {
        font-weight: 600;
      }
    }
  }
}

.ant-card {
  border-radius: 4px;
}

.ant-popover-arrow {
  display: none;
}


// NB: Т.к. для линейчатого прогресс бара используется bootstrap,
// то класс row должен быть приведен к стилям от ант дизайна
.row {
  margin-right: 0;
  margin-left: 0;
}

.ant-modal-mask {
  background-color: rgba(55, 64, 75, 0.7);
  backdrop-filter: blur(16px);
}

// antd steps
.ant-steps {
  align-items: center;
}

.ant-steps-item-container {
  display: flex;
  align-items: center;
}

.ant-steps-item-content {
  max-width: 110px;
}
.ant-steps-horizontal > .ant-steps-item {
}

.ant-steps-item-icon {
  height: 32px;
  width: 32px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-title {
  padding-right: 5px;
  &::after {
    top: 50%;
  }
}
.ant-steps-item {
  padding-left: 5px;
  white-space: normal !important;
}

.ant-steps-item-wait {
  & > .ant-steps-item-container {
    & > .ant-steps-item-content {
      & > .ant-steps-item-title {
        &::after {
        }
      }
    }
    & > .ant-steps-item-icon {
      border: 1px solid #1373E5;

      & > .ant-steps-icon {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #1373E5;
      }
    }
  }
}

.ant-steps-item-process {
  & > .ant-steps-item-container {
    display: flex;
    align-items: center;

    & > .ant-steps-item-icon {
      background: #1373E5;
      border-color: #1373E5;
    }

    & > .ant-steps-item-content {
      & > .ant-steps-item-title {
        color: #1373E5;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        &::after {
          background-color: #CED7E0;
        }
      }
    }
  }
}

.ant-steps-item-finish {
  & .ant-steps-item-icon {
    background: #1373E5;
    border-color: #1373E5;

    & > .ant-steps-icon {
      color: #FFFFFF;
    }
  }

  & > .ant-steps-item-container {
    & > .ant-steps-item-content {
      & > .ant-steps-item-description {
        font-size: 11px;
        line-height: 16px;
        color: #1373E5;
        cursor: pointer;
      }

      & > .ant-steps-item-title {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #0F1011;
      }
    }
  }
}


// data picker
.ant-picker-focused {
  box-shadow: none;
}


.zd-comment {
  h1 {
    line-height: 36px;
    margin-bottom: 5px;
  }
  img {
    height: 200px !important;
    width: 100% !important;
    margin: 5px 0;
    object-fit: contain;
  }
  & > p {
    margin-bottom: 0;
  }

}

.ant-layout {
  background-color: transparent !important;
}
