.root {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #0F1011;
  margin-bottom: 10px;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #42464B;
  margin-bottom: 20px;
}

.input {
  margin-bottom: 20px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
