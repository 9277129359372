// AnyComponent

// Variables
// =========

// Selectors

.root {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

.bank {
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(15, 16, 17, 0.07);
    border-radius: 5px;
    padding: 20px 20px 15px;
}

.head {
    display: grid;
    grid-template-columns: 52px 1fr auto;
    margin-bottom: 10px;
}

.info {
    cursor: pointer;
}

.dots {
    position: relative;
    cursor: pointer;
  }

.logo {
    width: 32px;
    height: 32px;
    border-radius: 10px;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}

.spinner {
    position: relative;
    transform: rotate(0);
    animation: spinner 3s ease-in-out infinite;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.brand {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: auto;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
}

.mode {
    display: inline-flex;
    width: max-content;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 7px;
    border-radius: 5px;
    margin-left: 52px;

    &.connected {
        border: 1px solid #10B97B;
        color: #10B97B;
    }

    &.connecting {
        border: 1px solid #1373E5;
        color: #1373E5;
    }

    &.error {
        border: 1px solid #E3405E;
        color: #E3405E;
    }
}

.fields {
    display: none;
}

.buttons {
    padding-top: 10px;
}

.plug, .apply  {

    & button {
        width: 100%;
    }
}

.apply {
    margin-top: 10px;
}

.show {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    // margin-top: 15px;
    padding-top: 15px;
    font-size: 14px;
    line-height: 24px;
    color: #1373E5;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        width: calc(100% + 40px);
        height: 1px;
        background-color: #E8F1FD;
    }

    & svg {
        position: relative;
        transform: rotate(-90deg);
        top: 2px;
        transition: all 0.3s;
    }

    &.active {
        & svg {
            transform: rotate(90deg);
        }
    }

    .menu {
        position: absolute;
        color: #0F1011;
        padding: 5px;
        top: 0 !important;
        right: 0 !important;
        left: unset !important;
        bottom: unset !important;
      }

}

// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .apply, .plug {

        & button {
            width: unset;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .root {
        margin-bottom: 20px;
    }

    .bank {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 16px 20px 15px;
    }

    .head {
        display: flex;
        align-items: center;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin-bottom: 0;
    }

    .logo, .brand, .info {
        margin-right: 20px;
    }

    .mode {
        margin-left: 0;
    }

    .info {
        display: flex;
        align-items: center;
    }

    .wrapper {
        grid-column: 1 / 3;
        grid-row: 3 / 4;

        & > div:not(:last-child) {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -20px;
                width: calc(100% + 40px);
                height: 1px;
                background-color: #E8F1FD;
            }
        }
    }

    .buttons {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        display: flex;
        gap: 10px;
        margin-left: auto;
        padding-top: 0;
    }

    .apply {
        margin-top: 0;
    }

    .fields {
        position: relative;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        display: flex;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        margin-top: 16px;
        padding-bottom: 16px;

        & .th {
            word-break: break-all;

            &:not(:last-child) {
                margin-right: 3px;
            }
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -20px;
            width: calc(100% + 40px);
            height: 1px;
            background-color: #E8F1FD;
        }
    }

    .show {
        grid-column: 1 / -1;
        grid-row: 4 / 5;
    }

    .status {
        flex: 1 1 130px;
    }

    .sum {
        flex: 1 1 63px;
    }

    .percent {
        flex: 1 1 90px;
    }

    .returnSum {
        flex: 1 1 90px;
    }

    .applyDate {
        flex: 1 0 95px;
    }

    .returnDate {
        flex: 1 0 159px;
    }

    .way {
        flex: 1 0 130px;
    }

    .buttons {
        flex: 1 0 204px;
    }
}

// Extra large devices (large desktops, 1500px and up)
@media (min-width: 1500px) {
    .status {
        flex: 1 1 150px;
    }

    .sum {
        flex: 1 1 130px;
    }

    .percent {
        flex: 1 1 130px;
    }

    .returnSum {
        flex: 1 1 130px;
    }

    .applyDate {
        flex: 1 0 130px;
    }

    .returnDate {
        flex: 1 0 180px;
    }
}
// ============
