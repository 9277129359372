// AnyComponent

// Variables
// =========

// Selectors

.root {
  background: rgba(19, 115, 229, 0.1);
  border-radius: 5px;
  padding: 18px 20px;
  font-size: 15px;
  line-height: 28px;
  color: #0F1011;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

:global .highlighted {
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #1373E5;
}

.img {
  width: 24px;
  height: 24px;
}

.info {
  margin-right: 10px;
}

:global .fincoin {
  margin-right: 6px;
  width: 24px;
  height: 24px;
}


// =========

// MediaQueries

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .root {
    width: max-content;
    margin-bottom: 10px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1300px) {

}

// ============
