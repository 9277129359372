// Description
// =======

// Variables

$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-item: #42464B;

// =========

// Selectors
// основные стили, фирст мобаил
// для десктопа в медиа запросах

.mainInfo {
  display: none;
  max-width: 470px;
  width: 100%;
  & img {
    display: block;
    width: 60%;
    margin-bottom: 48px;
  }
}

.info {
  &Title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $color-main-black;
    margin-bottom: 30px;
  }
  &List {
    list-style: none;
    padding-left: 0;
    & li {
      position: relative;
      font-size: 17px;
      line-height: 32px;
      color: $color-item;
      padding-left: 21px;
      &:before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        top: 13px;
        left: 0;
        border-radius: 50%;
        background-color: $color-main-blue;
      }
    }
    & li:not(:last-child) {
      margin-bottom: 23px;
    }
  }
}

// =========

// MediaQueries
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

@media (min-width: 1141px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .mainInfo {
    display: block;
  }

}
// ============

