.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.title {
  color: #5A6068;
  font-size: 13px;
  line-height: 20px;
}

.number {
  text-align: center;
  min-width: 90px;
  padding: 5px 30px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}

.success {
  color: #10B97B;
  background: rgba(16, 185, 123, 0.15);
}

.danger {
  background: rgba(227, 64, 94, 0.15);
  color: #E3405E
}

.normal {
  background: #E8F1FD;
  color: #1373E5;
}
