// LayoutSidebar

// Variables
// =========

// Selectors
.sider {
  box-shadow: 1px 0 5px 0 rgba(13, 14, 72, 0.05),
    1px 0 1px 0 rgba(13, 14, 72, 0.03);
  z-index: 100;
  flex: 0 0 225px !important;
  max-width: 225px !important;
  min-width: 225px !important;
  width: 225px !important;
}

// =========

// MediaQueries
// ============
