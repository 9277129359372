// BalanceBadge

// Variables
// =========

// Selectors

.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  margin: auto;
}

.success {
  color: #1373E5 !important;
  background-color: rgba(19, 115, 229, 0.15) !important;
}

.warning {
  color: #FABD48 !important;
  background-color: rgba(250, 189, 72, 0.15) !important;
}

.danger {
  color: #E3405E !important;
  background-color: rgba(227, 64, 94, 0.15) !important;
}

.iconPadding {
  padding-right: 4px;
  padding-top: 1px;
}

.iconBalance {
  margin-right: 6px !important;
}
// =========

// MediaQueries
// ============
@media (max-width: 1199.98px) {

  .badge {
    margin: 0;
    padding: 1px 10px;
  }
}
