// OfferElement

// Variables

$color-blue-transparent: #E8F1FD;
$color-orange: #FABD48;
$color-green: #10B97B;
$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-text: #5A6068;
$color-grey-icon: #979797;
$color-grey-title: #9CA5B0;
$color-light-grey-border: #CED7E0;
$color-button-hover: #398bef;

// =========

// Selectors

.select {

  & span {
    visibility: hidden;
  }

  & svg {
    visibility: hidden;
  }
}

.wrapper {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  box-shadow: 0 1px 5px 0 rgba(13, 14, 72, 0.05),
  0 1px 1px 0 rgba(13, 14, 72, 0.03);
}

.mainWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.logo {
  margin-right: 20px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
}

.header {
  display: flex;
  margin-bottom: 10px;
}

.ratingLabel {
  width: 93px;
  margin-right: 8px;
  display: flex;

  & span {
    text-align: end;
  }
}

.ratingLabelSpan {
  height: 30px;
  width: 93px;
  color: #73797f;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 3px;
}

.ratingWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-right: 20px;
  margin-top: 12px;
}

.chart {
  margin-bottom: 5px;
}

.subHeader {
  font-family: Montserrat, sans-serif;
  color: #37404b;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
}

.feedbackRating {
  margin-bottom: 10px;

  & img {
    margin-right: 2px;
  }

  & span {
    color: #73797f;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;
    margin-left: 4px;
  }
}

.issueBtn {
  margin-bottom: 10px;
}

.data {
  display: flex;
  margin-bottom: 10px;
}

.dataLabel {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 30px;
}

.dataLabelMain {
  color: #73797f;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 6px;
}

.dataLabelSub {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  letter-spacing: -0.09px;
  line-height: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  font-size: 22px;
  color: #37404b;
}

.infoOfferHeaderCategory {
  margin-top: 10px;
}

.label_dadata {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
}

.img {
  margin-right: 5px;

  &Confirmed {

  }
}

.mainWrapperOffer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.headerOffer {
  display: flex;
  margin-bottom: 22px;
}

.logoOffer {
  margin-right: 10px;
}

.textOfferName {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #37404b;
}

.infoOffer {
  display: flex;
  flex-direction: column;
}

.infoOfferHeader {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #37404b;
  margin-bottom: 10px;
}

.infoOfferSubHeader {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.0928571px;
  color: #73797f;
  margin-bottom: 10px;
}

.formOffer {
  display: flex;
  margin-bottom: 30px;
}

.footerOffer {
  display: flex;
  justify-content: space-between;
}

.leftSideBtns {
  display: flex;
}

.rightSideBtns {
  display: flex;
}

.errorMessage {
  margin-top: 6px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #e26b84;
}

.inputLabel {
  color: #37404b;
  font-family: Montserrat, sans-serif;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  height: 40px !important;
  border: 1px solid rgba(214, 220, 227, 0.5) !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;

  font-family: Montserrat, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: 0 !important;
  line-height: 17px !important;

  &:active,
  &:focus {
    border: 1px solid #1373e5 !important;
    box-shadow: none !important;
  }
}

// new styles

.offer {
  max-width: 1330px;
  position: relative;
  padding: 18px 20px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 1px 5px rgba(13, 14, 72, 0.05), 0px 1px 1px rgba(13, 14, 72, 0.03);
  &__img {
    object-fit: contain;
    & img {
      max-width: 140px;
    }
  }
}

.offer__inner {
  display: grid;
  grid-template-columns: 160px 1fr 1fr minmax(282px, 1fr) 1fr;
}

.offer__better, .offer__partner {
  display: none;
  min-height: 28px;
  position: absolute;
  align-items: center;
  top: 0;
  left: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  border-radius: 5px 0px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  & img {
    margin-right: 6px;
  }
}

.offer__better {
  background-color: $color-green;
}

.offer__partner {
  background-color: $color-main-blue;
}

.offer.offerBetter {
  .offer__better {
    display: inline-flex;
  }
}

.offer.offerPartner {
  .offer__partner {
    display: flex;
  }
}

.offer.offerWithoutPayment {
  .offer__info_payment, .offer__info_overpayment {
    display: none;
  }
  .offer__info {
    grid-template-columns: 1fr 1fr;
    align-content: end;
    & > div {
      flex-direction: column;
    }
    .offer__info_title {
      width: auto;
    }
    & span {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
  .offer__text, .buttonRequirements {
    display: none;
  }
}

.offer.offerCards {
  .offer__inner {
    grid-template-columns: 205px 1fr 282px minmax(280px, 280px);
  }
  .offer__img {
    grid-row: 1 / 3;
    & img {
      max-width: 185px;
    }
  }
  .offer__text {
    display: none;
  }
  .offer__sum {
    display: none;
  }
  .offer__bank, .offer__info {
    margin-left: 0;
  }
  .offer__info {
    display: flex;
    grid-column: 2 / 5;
    grid-row: 2 / 3;
    & > div {
      flex-direction: column;
      margin-right: 60px;
    }
    & span {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
  .offer__info_title {
    width: 100%;
  }
  .buttonRequirements {
    display: none;
  }
}

.offer.offerCardsBonus {
  .offer__info_count {
    & span {
      font-weight: normal;
    }
  }
  .offer__buttons {
    grid-row: 1 / 2;
    grid-column: 4 / 5;
  }
  .offer__credit_rating {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
}

.offer__bonus {
  margin-top: 17px;
  grid-column: 2 / 5;
  grid-row: 3 / 4;
  display: flex;
  margin-bottom: -12px;
}

.offer__bonus_item {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 12px;
  .offer__bonus_item_icon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    flex-shrink: 0;
  }
  & img {
    max-width: 100%;
    width: 100%;
  }
  .offer__bonus_item_data_name {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: $color-grey-title;
  }
  .offer__bonus_item_data_value {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $color-main-black;
  }
}

.offer__bank {
  margin-left: 30px;
}

.offer__bank_name {
  font-size: 13px;
  line-height: 20px;
  color:$color-grey-title;
  margin-bottom: 5px;
}

.offer__product {
  color: $color-main-black;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.offer__rating {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.offer__rating_listStar {
  display: flex;
  & li:not(:last-child) {
    margin-right: 3px;
  }
}

.offer__rating_number_review {
  margin-left: 10px;
  color: $color-grey-title;
  font-size: 11px;
  line-height: 13px;
}

.offer__sum {
  color: $color-main-black;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  & a {
    color: $color-main-black;
    text-decoration: none;
  }
}

.offer__info_count {
  & a {
    color: $color-main-black;
    text-decoration: none;
  }
}

.offer__info {
  display: grid;
  align-content: space-between;
  grid-template-columns: 1fr;
  white-space: nowrap;
  & > div {
    display: flex;
  }
  color: $color-grey-title;
  font-size: 13px;
  line-height: 20px;
  & span {
    color: $color-main-black;
  }
}

.offer__info_payment, .offer__info_overpayment {
  & i {
    position: relative;
    display: inline-block;
    top: 2px;
    width: 13px;
    height: 13px;
    margin-left: 9px;
    //background-image: url(../assets/images/question.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    &:hover {
      //background-image: url(../assets/images/question_blue.svg);
      .offer__info_tooltip {
        display: block;
      }
    }
  }
}

.offer__info_tooltip {
  display: none;
  position: absolute;
  width: 310px;
  bottom: 100%;
  right: -148px;
  padding-bottom: 15px;
  background-color: transparent;
  white-space: pre-wrap;
}

.offer__info_tooltipWrap {
  position: relative;
  background-color: #fff;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 29px rgba(87, 87, 92, 0.23);
  &::after {
    content: '';
    position: absolute;
    bottom: -13px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 14px solid #fff;
  }
}

.offer__info_title {
  // width: 42%;
  width: 35%;
}

.offer__credit_rating_title {
  font-size: 13px;
  line-height: 20px;
  color: $color-main-black;
  margin-bottom: 2px;
}

.offer__credit_rating_wrap {
  display: flex;
  margin: 0 -3px 0 0;
  & > div {
    width: calc(25% - 3px);
    margin-right: 3px;
  }
}

.rating__credit__value {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
}

.rating__credit__scale {
  height: 7px;
  margin: 4px 0px;
  border-radius: 10px;
}

.rating__credit__designation {
  font-size: 11px;
  line-height: 16px;
}

.rating__creditZero, .rating__creditBad {
  .rating__credit__value, .rating__credit__designation {
    color: $color-blue-transparent;
  }
  .rating__credit__scale {
    background-color: $color-blue-transparent;
  }
}

.rating__creditGood {
  .rating__credit__value {
    color: $color-orange;
  }
  .rating__credit__scale {
    background-color: $color-orange;
  }
  .rating__credit__designation {
    color: $color-text;
  }
}

.rating__creditExcellent {
  text-align: right;
  .rating__credit__value {
    color: $color-green;
  }
  .rating__credit__scale {
    background-color: $color-green;
  }
  .rating__credit__designation {
    color: $color-text;
  }
}

.offer__buttons {
  display: flex;
  flex-direction: column;
}

.offer__buttons {
  margin-left: 40px;
}

.button {
  border: none;
  background-color: transparent;
  border-radius: 22px !important;
  padding: 8px 30px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.3s;
  height: 38px !important;
  font-family: 'Montserrat', sans-serif;

  &Money {
    color: #FFFFFF !important;
    background-color: $color-main-blue !important;
    margin-bottom: 10px;
    border: 1px solid transparent !important;
    &:hover {
      background-color: $color-button-hover;
    }
  }

  &Confirmed {
    cursor: auto;
    background-color: #10B97B !important;
    &:hover {
      background-color: #10B97B;
    }
  }
}


.buttonReviews {
  color: $color-main-blue;
  border: 1px solid $color-main-blue;
  &:hover {
    border: 1px solid $color-main-blue;
  }
}

.buttonRequirements {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-main-blue;
  padding: 0px 0px;
  margin: 17px auto 0;
  width: max-content;
  font-weight: 400;
  & img {
    margin-left: 5px;
  }
}

.offer__text {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid $color-light-grey-border;
}

.offer__text_title {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 9px;
}

.offer__text_list {
  column-width: 44%;
  column-count: 2;
  column-gap: 4%;
  // ниже св-ва чтобы текст не перескакивал на другую колонку
  overflow: hidden; /* Fix for firefox and IE 10-11  */
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  break-inside: avoid-column;
  & > li {
    position: relative;
    font-size: 13px;
    line-height: 20px;
    padding-left: 22px;
    margin-bottom: 5px;
    & img {
      margin-right: 10px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0px;
      width: 12px;
      height: 12px;
      background-image: url(./assets/images/arrow_check.svg);
    }
  }
}

// =========

// MediaQueries

@media screen and (max-width: 1560px) {

  .offer__inner {
    grid-template-columns: 160px 1fr minmax(282px, 1fr);
  }

  .offer__img {
    grid-row: 1 / 4;
  }

  .offer__bank {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    margin-bottom: 8px;
  }

  .offer__info {
    // max-width: 500px;
    max-width: 545px;
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-left: 30px;
    grid-row-gap: 12px;
    margin-bottom: 20px;
  }

  .offer__info_title {
    width: 40%;
  }

  .offer__credit_rating {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }

  .offer__buttons {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    margin-left: 0;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    .button {
      max-width: 100%;
      width: 100%;
    }
  }

  .buttonMoney {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .buttonRequirements {
    margin: 0;
  }

  .offer.offerWithoutPayment {
    .offer__bank {
      grid-row: 1 / 3;
    }
    .offer__info {
      display: flex;
      grid-column: 3 / 4;
      grid-row: 2 / 3;
      grid-auto-flow: row;
      margin-left: 0;
      margin-top: 25px;
    }
    .offer__sum {
      margin-top: 25px;
    }
    .offer__info_rate {
      margin-right: 61px;
    }
  }

  .offer.offerCards {
    .offer__inner {
      grid-template-columns: 205px 1fr 282px minmax(280px, 280px);
    }
    .offer__img {
      grid-row: 1 / 3;
    }
    .offer__bank, .offer__info {
      margin-left: 0;
    }
    .offer__info {
      display: flex;
      max-width: 100%;
      grid-column: 2 / 5;
      grid-row: 2 / 3;
      & > div {
        flex-direction: column;
        margin-right: 60px;
      }
    }
    .offer__buttons {
      flex-direction: column;
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      margin-left: 40px;
    }
    .buttonMoney {
      margin-left: 0;
    }
  }

  .offer.offerCardsBonus {
    .offer__bonus {
      display: grid;
      //grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-template-columns: repeat(4, minmax(100px, 170px));
      //grid-gap: 30px;
    }
  }
}

.danger {
  .rating__credit__value {
    color: red;
  }
  .rating__credit__scale {
    background-color: red;
  }
}

.active {
  .rating__credit__value {
    color: $color-blue-transparent;
  }
  .rating__credit__scale {
    background-color: $color-blue-transparent;
  }
  .rating__credit__designation {
    color: $color-blue-transparent;
  }
}

.warning {
  .rating__credit__value {
    color: $color-orange;
  }
  .rating__credit__scale {
    background-color: $color-orange;
  }
  .rating__credit__designation {
    color: $color-text;
  }
}

.success {
  text-align: right;
  .rating__credit__value {
    color: $color-green;
  }
  .rating__credit__scale {
    background-color: $color-green;
  }
  .rating__credit__designation {
    color: $color-text;
  }
}

@media screen and (max-width: 1280px) {

  .offer.offerCards {
    .offer__inner {
      grid-template-columns: 205px 1fr 282px;
    }
    .offer__buttons {
      flex-direction: row;
      grid-column: 2 / 4;
      grid-row: 3 / 4;
      margin-left: 0px;
    }
    .buttonMoney {
      margin-right: 10px;
    }
    .offer__info {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: minmax(150px, 200px) minmax(150px, 200px);
    }
  }

  .offer.offerCards.offerCardsBonus {
    .offer__buttons {
      grid-row: 4 / 5;
      margin-top: 21px;
    }
    .offer__info {
      grid-template-columns: 1fr 1fr;
    }
  }


}

@media screen and (max-width: 1200px) {
  .menu {
    display: none;
  }

}


@media screen and (max-width: 800px) {

  .offer__inner {
    grid-template-columns: 115px 1fr;
  }

  .offer__img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .offer__bank {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin-left: 0;
  }

  .offer__info {
    display: block;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    margin-left: 0;
    & > div {
      margin-bottom: 5px;
    }
  }

  .offer__credit_rating {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .offer__buttons {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .offer__credit_rating_title {
    font-size: 11px;
    line-height: 16px;
    word-break: break-all;
  }

  .rating__credit__value {
    font-size: 15px;
    line-height: 22px;
  }

  .buttonMoney {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .buttonRequirements {
    margin-top: 15px;
  }

  .rating__credit {
    text-align: left;
  }

  .offer__info_title {
    width: 25%;
  }

  .offer__text_list {
    column-count: 1;
  }

  .offer.offerWithoutPayment {
    .offer__bank {
      grid-row: unset;
    }
    .offer__info {
      flex-direction: column;
      grid-column: 1 / 3;
      grid-row: unset;
      grid-auto-flow: row;
      margin-left: 0;
      margin-top: 0px;
      & > div {
        flex-direction: row;
      }
      .offer__info_title {
        width: 40%;
      }
      & span {
        font-weight: 400;
      }
    }
    .offer__sum {
      margin-top: 0px;
    }
    .offer__info_rate {
      margin-right: 0px;
    }
  }

  .offer.offerCards {
    .offer__inner {
      grid-template-columns: 140px 1fr;
      grid-column-gap: 40px;
    }
    .offer__img {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      & img {
        max-width: 100%;
      }
    }
    .offer__info {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
    .offer__buttons {
      flex-direction: column;
      grid-column: 1/ 3;
      grid-row: 4 / 5;
    }
    .buttonMoney {
      margin-right: 0px;
    }
    .offer__info {
      display: grid;
      grid-auto-flow: row;
    }
  }

  .offer.offerCards.offerCardsBonus {
    .offer__info {
      grid-column: 1 / 3;
      & > div {
        margin-right: 0;
      }
      .offer__info_title {
        flex-shrink: 0;
      }
    }
    .offer__bonus {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
    }
    .offer__buttons {
      grid-column: 1 / 3;
      grid-row: 5 / 6;
    }
    .offer__credit_rating {
      grid-column: 2 / 3;
    }

  }

}

@media screen and (max-width: 670px) {

  .offer.offerCards.offerCardsBonus {
    .offer__bonus {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 600px) {

  .offer__credit_rating {
    justify-self: end;
  }

  .offer__credit_rating_wrap > div {
    width: auto;
    margin-right: 10px;
  }

  /* .offer__img {
    img {
      max-width: 120px;
    }
  } */

  .offer {
    &__img {
      & img {
        max-width: 120px;
      }
    }
  }

  .rating__credit__scale, .rating__credit__designation {
    display: none;
  }

  .rating__creditZero, .rating__creditBad {
    display: none;
  }

  .offer.offerCards {
    .offer__inner {
      grid-column-gap: 20px;
    }
    .offer__info {
      display: flex;
      flex-direction: column;
      & > div {
        flex-direction: row;
        margin-right: 0;
      }
      .offer__info_title {
        width: 40%;
      }
      & span {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }

}


@media screen and (max-width: 470px) {

  .offer__info_title {
    width: 35%;
  }

  .offer__info_tooltip {
    right: -43px;
  }

  .offer__info_tooltipWrap::after {
    left: 210px;
  }

  .offer.offerCards {
    .offer__info {
      .offer__info_title {
        width: 50%;
      }
    }
  }

  .offer.offerCards.offerCardsBonus {
    .offer__bonus_item_icon {
      width: 14px;
      height: 14px;
    }
    .offer__info {
      margin-bottom: 0;
    }
    .offer__bonus {
      margin-top: 12px;
    }
  }


}

// ============
