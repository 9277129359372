$main-black: #0F1011;
$main-color: #1373e5;
$error-color: #E24848;
$text-color: #5A6068;
$light-blue-color: #E8F1FD;
$green-color: #10B97B;
$light-grey-color: #CED7E0;
$background-color: #F5F7FA;
$light-grey-text: #9CA5B0;
$red-color: #E3405E;
$yellow-color: #FABD48;
$hover-blue: #EAF1F9;

