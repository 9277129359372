.ant-btn-primary {
  background-color: $main-color;
  border-color: transparent;
  font-family: 'Montserrat', sans-serif;
  padding-left: 30px;
  padding-right: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #398bef;
    border-color: transparent;
  }
  &:active,
  &:visited {
    background-color: #1167d0;
  }
  &:disabled {
    border-color: transparent;
    color: #ffffff;
    background-color: #b8d5f7;
  }
}

.ant-btn-default {
  color: #37404b;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #e4e6ea;
  background-color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;

  &:hover,
  &:focus {
    color: #37404b;
    border-color: $main-color;
  }
  &:active,
  &:visited {
    border: 1px solid #37404b;
  }
  &:disabled {
    border: 1px solid #f2f4f7;
    color: rgba(115, 121, 127, 0.2);
    background-color: #ffffff;
  }
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link:active,
.ant-btn-link:visited,
.ant-btn-link:focus {
  color: $main-color;
}

.ant-btn {
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
}

.ant-btn.img-left {
  img {
    margin-right: 10px;
    margin-top: -3px;
  }
}

.ant-btn.img-right {
  img {
    margin-left: 10px;
    margin-top: -3px;
  }
}

.header {
  .ant-btn {
    &:hover,
    &:focus {
      border: 1px solid rgba(214, 220, 227, 0.4);
    }
  }
}

.btn-heart {
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  margin-right: 20px;
  border: 1px solid rgba(214, 220, 227, 0.4);
  border-bottom: 0px;
  box-shadow: none;
  img {
    margin-left: 10px;
    margin-right: 10px;
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 0px !important;
    box-shadow: none !important;
  }
}

.btn-profile {
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  margin-left: 20px;
  border: 1px solid rgba(214, 220, 227, 0.4);
  border-bottom: 0px;
  box-shadow: none;
  img {
    width: 38px;
    height: 38px;
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 0px !important;
    box-shadow: none !important;
  }
}

.btn-badge {
  height: 100%;
  border: 1px solid rgba(214, 220, 227, 0.4);
  border-bottom: 0;
  border-left: 0;
  box-shadow: none;
  img {
    margin-right: 10px;
  }
  p {
    font-size: 11px;
    margin-bottom: 5px;
  }
  &:hover,
  &:active,
  &:focus {
    border-bottom: 0px !important;
    border-left: 0 !important;
    box-shadow: none !important;
  }
}

.badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 2px 8px 2px 8px;
  border-radius: 3px;
  font-size: 13px;
}
.badge.success {
  // color: #82C17A;
  color: #ffffff;
  background-color: #1373e5;
  // background-color: rgba(130,193,122,0.2);
}
.badge.danger {
  // color: #e26b84;
  color: #ffffff;
  // background-color: rgba(226, 107, 132, 0.2);
  background-color: #e3405e;
}

.ant-switch-checked {
  background: #1373E5;
}
