// NotifyDescription

// Variables
// =========

// Selectors

.desc {
  color: #37404b;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
}

.sprtInfo {
  color: #1373e5;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: 0;

  &:hover {
    cursor: pointer;
    color: #1373e5;
  }

  &:active {
    color: #1373e5;
  }

  &:focus {
    color: #1373e5;
  }
}

// =========

// MediaQueries
// ============
