// FeedbackOrgRatingItem

// Variables
// =========

// Selectors

.wrapper {
  & img {
    margin-right: 2px;
  }

  & span {
    color: #73797f;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 15px;
    margin-left: 4px;
  }
}

// =========

// MediaQueries
// ============
