// UserSettings

// Variables
@import '../../../../assets/styles/variables.scss';
// =========

// Selectors
.header {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: $main-black;
  margin-bottom: 30px;
}

.menu {
    display: flex;
    margin-bottom: 20px;
}

.menu__item {
    display: flex;
    align-items: center;
    margin-right: 5px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(15, 16, 17, 0.07);
    border-radius: 4px;
    padding: 16px 20px 16px 24px;
    border: 2px solid transparent;
    transition: all 0.3s linear;
    &.active {
        border: 2px solid $main-color;
        background-color: $light-blue-color;
    }
    &:hover {
        border: 2px solid $main-color;
        background-color: $light-blue-color;
    }
    & img {
        margin-right: 12px;
    }
    & span {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: $main-black;
    }
}

.shopping__subtitle {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
    margin-bottom: 20px;
}


.limit__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $background-color;
    padding: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: $main-black;
}

.limit__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid $light-blue-color;
    &:last-child {
        border-bottom: 1px solid transparent;
    }
}

.limit__item_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $main-black;
}

.limit__item_text {
    max-width: 525px;
    width: 100%;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
}

.limit__count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    margin-left: 10px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $main-color;
}

.limit__ad {
    max-width: 525px;
    width: 100%;
    padding: 15px 20px 20px;
    background-color: $light-blue-color;
    margin-top: 10px;
    border-radius: 4px;
    & button {
        width: 100%;
    }
}

.limit__ad_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $main-black;
    margin-bottom: 10px;
}

.limit__ad_text {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: $text-color;
    margin-bottom: 15px;
}


.listDesktop {
    margin-bottom: 14px;

    & .listDesktopEl:nth-child(odd) {
      background: $background-color;
    }
    & .listDesktopEl:nth-child(even) {
      background: #ffffff;
    }

    & > .listDesktopEl:nth-child(1) {
      background: $background-color;
    }

    & .listDesktopEl:last-child {
      border-bottom: 1px solid $light-blue-color;
    }
  }

  .listDesktopEl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 8px 10px;

    & > div {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.0928571px;
      color: $main-black;
    }
  }

  .listDesktopElHeader.listDesktopElHeader {
    padding: 10px;
    & > div {
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: $main-black;
    }
  }

  .flex1 {
    flex: 1 0 123px;
  }
  .flex2 {
    display: flex;
    align-items: center;
    flex: 1 0 90px;
    & img {
        margin-right: 8px;
    }
  }
  .flex3 {
    flex: 0 0 55px;
  }


// =========

// MediaQueries
@media (min-width: 380px) {
    .flex3 {
        flex: 0 0 65px;
      }

    .flex3 {
      flex: 0 0 105px;
    }
}

@media only screen and (min-width: 768px) {
    .limit__ad {
        & button {
            width: unset;
        }
    }
}
// ============

.btn {
  height: auto !important;
  margin-top: 10px !important;
  border: 1px solid #1373e5 !important;
  color: #1373e5 !important;
  padding: 15px 20px !important;
}

.center {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
