.wrapper {
  width: 280px;
  max-width: 280px;
}

.speedometer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  color: #0F1011;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 15px;
}

.line {
  position: relative;
  width: 100%;
  height: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::before, &::after {
    content: '';
    background: #E8F1FD;
    width: 100px;
    height: 1px;
  }
}

.buy {
  margin-top: 20px;
  width: 100%;
  border-radius: 22px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  margin-bottom: 10px;
  padding: 10px 20px !important;
  height: 100% !important;
}

.smallBalance {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 20px;
  font-size: 13px;
  line-height: 20px;
  color: #5A6068;

  & svg {
    margin-right: 6px;
  }
}

.wallet {
  display: flex!important;
  justify-content: center;
  align-items: center !important;
  width: 100%;
  border-radius: 22px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  padding: 10px 20px !important;
  height: 100% !important;
  color: #1373E5 !important;

  & svg {
    margin-right: 6px;
  }
}
