// Register

// Variables

$color-blue-transparent: #E8F1FD;
$color-orange: #FABD48;
$color-green: #10B97B;
$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-text: #5A6068;
$color-grey-icon: #979797;
$color-grey-title: #9CA5B0;
$color-light-grey-border: #CED7E0;
$color-border-menu: #EAF1F9;
$color-background: #F5F7FA;
$color-disable-button: #B8D5F7;
$color-hover-button: #398bef;
$color-item: #42464B;
$color-placeholer: #87919C;

// =========

// Selectors

html, body {
  overflow-x: hidden;
  height: 100%;
}

.wrapper {
  position: relative;
  min-height: calc(100vh - 65px);

  &ColWhite {
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #fff;
  }

  &ColGrey {
    height: 100%;
    position: absolute;
    width: 0%;
    right: 0;
    background-color: $color-background;
  }

}

.main {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  &Inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    padding-top: 30px;
    padding-bottom: 114px;
  }
  &Registration {
    max-width: 490px;
    width: 100%;
    margin: 0 auto;
    & a {
      color: $color-main-blue;
    }
  }
}


.registration{
  &Buttons {
    display: flex;
    border-bottom: 1px solid $color-border-menu;
    margin-bottom: 20px;
  }
  &Btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $color-main-black;
    padding: 0 0 8px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    &Active {
      color: $color-main-blue;
      border-bottom: 2px solid $color-main-blue;
    }
    &:hover {
      color: $color-main-blue;
      border-bottom-color: $color-main-blue;
    }
    &SignIn {
      margin-right: 20px;
    }
  }
}

// =========


// MediaQueries


@media (min-width: 768px) {

  .wrapper {
    min-height: calc(100vh - 72px);
  }

  .main {
    min-height: calc(100vh - 72px);

    &Inner {
      padding-top: 163px;
    }

    &Registration {
      max-width: 600px;
    }
    
  }

  .registrationButtons {
    margin-bottom: 30px;
  } 

  .registrationBtn {
    padding: 0 10px 10px;
    font-size: 15px;
    line-height: 28px;
  }


}


@media (min-width: 1200px) {

  .main {
    min-height: calc(100% - 65px);

    &Inner {
      margin-top: 0px;
      padding-top: 159px;
    }

    &Registration {
      margin: 0;
      max-width: 490px;
    }

  }

  .wrapper {

    &ColWhite {
      width: 50%;
    }

    &ColGrey {
      width: 50%;
    }
  }

  .registrationBtn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0 20px 16px;
  }

}


// ============
