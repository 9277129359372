// ModalWrapper

// Variables
// =========

// Selectors

.wrapper {
  //position: fixed;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //background: rgba(55, 64, 75, 0.702906);
  backdrop-filter: blur(16.3097px);
  //z-index: 200;
}


.wrapperBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 3px 10px rgba(13, 14, 72, 0.0720334), 0 1px 1px rgba(13, 14, 72, 0.0528846);
  border-radius: 4px;
  padding: 25px 20px;
  transition: height, width 3s ease;
}

// =========

// MediaQueries
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
// ============
