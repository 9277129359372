// UserSettingsChangePhoneForm

// Variables
// =========

// Selectors

.header {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.headerText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #37404b;
  margin-right: 11px;
}

.subHeader {
  display: flex;
  margin-bottom: 14px;
}

.subHeaderText {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.0857143px;
  color: #73797f;
}

.headerIcon {
  &:hover {
    cursor: pointer;
  }
}

.info {
  margin-bottom: 20px;
}

.list {
  margin-bottom: 20px;
}

.infoPropsList {
  display: flex;
  flex-direction: column;
}

.listEl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.listElPropName {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;
}

.listElPropValue {
  display: flex;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #37404b;

  & > :first-child {
    margin-right: 6px;
  }
}

.infoAvatar {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 0px;
}

.infoAvatarMobile {
  padding: 0px;
}

.avatar {
  width: 152px;
  height: 152px;
  background: #e5eaef;
  border-radius: 50%;
}

.lawText {
  margin-bottom: 20px;

  & > p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: -0.0928571px;
    color: #73797f;
  }
}

.fieldWrapper {
  margin-top: 0;
  margin-bottom: 10px;
}

.formFooter {
  padding-top: 11px;
}

.errorMessage {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #e26b84;
}

.label {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #0F1011;
  margin-bottom: 4px;
}

$color-blue-transparent: #E8F1FD;
$color-main-blue: #1373E5;
$color-main-black: #0F1011;
$color-text: #5A6068;
$color-grey-title: #9CA5B0;
$color-light-grey-border: #CED7E0;
$color-border-menu: #EAF1F9;
$color-background: #F5F7FA;
$color-disable-button: #B8D5F7;
$color-hover-button: #398bef;
$color-item: #42464B;
$color-placeholer: #87919C;

.input {
  position: relative;
  width: 100%;
  transition: all 0.3s ease;
  height: 40px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #0F1011;

  letter-spacing: 0;
  font-weight: 500;

  border: 1px solid rgba(214,220,227,0.5);
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 13px !important;
  line-height: 17px;
  padding: 4px 11px;

  &:active,
  &:focus {
    outline: none;
    border: 1px solid $color-main-blue;
  }
  &:hover {
    border: 1px solid $color-main-blue;
  }
  &::placeholder {
    color: $color-placeholer;
    font-size: 15px;
    line-height: 28px;
  }
}

// =========

// MediaQueries

// Universal Mobile
@media (max-width: 1199.98px) {
  .fieldWrapper {
    margin-top: 8px;
  }

  .mobileButton {
    width: 100%;
  }
}

// ============
